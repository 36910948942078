import React from 'react'
import './styles.css'

export default (props) => {
    return (
        <div className="content-user">
            <div className="content-text-user">
                {props.text}
            </div>
        </div>
    )
}