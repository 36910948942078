import React from 'react'
import RequestBot from './services/RequestBot'
import reactStringReplace from 'react-string-replace'

import {
    ButtonLink,
    ButtonStep,
    ChatBotContainer,
    ContentBot,
    ContentButtons,
    ContentChatBot,
    ContentListQuestions,
    ContentUser,
    ContentVideo,
    Footer,
    Header,
    InputForm,
    ListQuestionStep,
    Video,
    ContentCarousel,
    CarouselStep,
    Cards,
    Loading
} from './components'

export default class App extends React.Component {

    /**
     *  Constructor
     * @param {object} props
     */
    constructor(props) {
        super(props);

        this.state = {
            botName: 'Orquestrador',
            botTitle: 'Assistente Virtual',
            displayChatBot: 'none',
            displayIconChatBot: 'block',
            messages: [],
            textToBeSent: '',
            btnVideo: false,
            onKeyPressUser: false,
            loading: true,
            placeholder: "Escreva aqui sua mensagem..."
        }

        this.client = new RequestBot();
        this.client.eventRequest('Welcome').then(this.onResponse, this);

        this.user = {
            id: 1,
            name: 'fs_user',
            avatarUrl: ''
        };

        this.bot = {
            id: 2,
            name: 'fs_hero',
            avatarUrl: ''
        };

        this.buttons = {
            id: 3,
            name: 'buttons'
        }

        this.video = {
            id: 4,
            name: 'video'
        }

        this.chat = {
            id: 5,
            name: 'chat'
        }

        this.list = {
            id: 6,
            name: 'list'
        }

        this.carousel = {
            id: 7,
            name: 'carousel'
        }

        this.card = {
            id: 8,
            name: 'card'
        }

        this.intentName = '';

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickButtonStep = this.handleClickButtonStep.bind(this);
        this.onClickProduct = this.onClickProduct.bind(this);
        this.handleEnded = this.handleEnded.bind(this);
    }

    /**
     * Componente Did Mount
     */
    componentDidMount() {
        this.setState({ placeholder: "( __ ) ____-____" })
    }

    /**
     * Function did update component
     */
    componentDidUpdate() {
        const objDiv = document.getElementById('content');
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    /**
     * Function that retrieves bot messages
     */
    onResponse = (activity) => {
        let time = 0;
        let that = this;
        let newMessage = [];

        activity.queryResult.fulfillmentMessages.forEach((element) => {
            if (element.platform === 'PLATFORM_UNSPECIFIED') {
                switch (element.message) {
                    case 'card':
                        newMessage.push({
                            author: this.card,
                            timestamp: new Date(),
                            suggestedActions: [{
                                type: "card",
                                title: element.card.title,
                                subtitle: element.card.subtitle,
                                image: element.card.imageUri,
                                button: element.card.buttons[0].text,
                                redirect_uri: element.card.buttons[0].postback
                            }]
                        });
                        break;

                    default:
                        newMessage.push({
                            text: reactStringReplace(
                                element.text.text,
                                /(https?:\/\/\S+)/g,
                                (match, i) => (
                                    <a key={match + i} rel="noopener noreferrer" target='_blank' href={match}>
                                        clique aqui
                                    </a>
                                )
                            ),
                            author: that.bot,
                            timestamp: new Date()
                        });
                        break;
                }
            }

            if (element.platform === 'ACTIONS_ON_GOOGLE') {
                switch (element.message) {
                    case 'suggestions':
                        newMessage.push({
                            author: this.buttons,
                            timestamp: new Date(),
                            suggestedActions: element.suggestions.suggestions.map(x => {
                                return { type: "buttons", title: x.title, value: x.title };
                            })
                        });
                        break;

                    case 'listSelect':
                        newMessage.push({
                            author: this.list,
                            timestamp: new Date(),
                            suggestedActions: element.listSelect.items.map(x => {
                                return { type: "list", title: x.title, value: x.title };
                            })
                        });
                        break;

                    case 'carouselSelect':
                        newMessage.push({
                            author: this.carousel,
                            timestamp: new Date(),
                            suggestedActions: element.carouselSelect.items.map(x => {
                                return {
                                    type: "carousel",
                                    title: x.title,
                                    description: x.description,
                                    value: x.title,
                                    img: x.image.imageUri
                                };
                            })
                        });
                        break;

                    case 'linkOutSuggestion':
                        if (element.linkOutSuggestion.uri.indexOf('youtube') > -1) {
                            this.setState({ btnVideo: true });

                            newMessage.push({
                                author: this.video,
                                timestamp: new Date(),
                                suggestedActions: [{
                                    type: "video",
                                    title: element.linkOutSuggestion.destinationName,
                                    value: element.linkOutSuggestion.uri
                                }]
                            });
                        } else {
                            newMessage.push({
                                author: this.chat,
                                timestamp: new Date(),
                                suggestedActions: [{
                                    type: "chat",
                                    title: element.linkOutSuggestion.destinationName,
                                    value: element.linkOutSuggestion.uri
                                }]
                            });
                        }
                        break;

                    default:
                        break;
                }
            }
        });

        if (activity.queryResult.webhookPayload) {
            newMessage.push({
                author: this.carousel,
                timestamp: new Date(),
                suggestedActions: activity.queryResult.webhookPayload.fields.null.structValue.fields.carousel.listValue.values.map(element => {
                    let plans = element.structValue.fields;
                    return {
                        type: "carousel",
                        title: plans.title.stringValue,
                        description: plans.description.stringValue,
                        value: plans.title.stringValue,
                        img: plans.image.stringValue,
                        button: plans.button.structValue.fields.text,
                        redirect_uri: plans.button.structValue.fields.url
                    }
                })
            });
        }
        
        newMessage.sort((a, b) => {
            if (a.author.id < b.author.id) return -1;
            if (a.author.id > b.author.id) return 1;
            return 0;
        });

        newMessage.forEach((data) => {

            time++;
            let count = time;

            setTimeout(function () {
                if (count === time) {
                    that.setState({ loading: false });
                }

                that.setState((prevState) => {
                    return { messages: [...prevState.messages, data] };
                });

            }, time * 1500);
        });
    }
    
    /**
    * Function handle change input form
    */
    handleChange = event => {
        this.setState({ textToBeSent: event.target.value });
    }

    /**
     * Function key press event input form
     */
    handleKeyPress = event => {

        if (event.key === 'Enter') {
            let value = this.state.textToBeSent;
            if (value !== '' && value !== undefined) {

                this.setState({ loading: true, placeholder: "Escreva aqui sua mensagem...", textToBeSent: '' });

                let btn = document.getElementsByClassName('content-buttons');

                for (var i = 0; i < btn.length; i++) {
                    btn[i].remove();
                }

                if (this.state.btnVideo) {
                    let video = document.getElementsByClassName('video');
                    for (let c = 0; c < video.length; c++) {
                        this.setState({ onKeyPressUser: true });
                        video.item(c).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                    }
                }

                document.getElementById('input').value = '';
                this.client.textRequest(value.toString()).then(this.onResponse, this);
                this.setState((prevState) => {
                    return {
                        messages: [...prevState.messages, {
                            author: this.user,
                            text: value,
                            timestamp: new Date()
                        }]
                    }
                })
            }
            event.preventDefault();
        }
    }


    /**
     * Function that starts an event when ending video
     */
    handleEnded = () => {
        this.client.eventRequest("FIM_VIDEO").then(this.onResponse, this);
        //this.clearForm();
    }

    /**
    * Function key press event input form
    */
    handleSubmit = event => {

        if (!event.value) {
            let value = this.state.textToBeSent;
            if (value !== '' && value !== undefined) {

                this.setState({ loading: true, placeholder: "Escreva aqui sua mensagem...", textToBeSent: '' });

                let lst = document.getElementsByClassName('content-questions');

                for (var i = 0; i < lst.length; i++) {
                    lst[i].remove();
                }

                let btn = document.getElementsByClassName('content-buttons');

                for (var b = 0; b < btn.length; b++) {
                    btn[b].remove();
                }

                if (this.state.btnVideo) {
                    let video = document.getElementsByClassName('video');
                    for (let c = 0; c < video.length; c++) {
                        this.setState({ onKeyPressUser: true });
                        video.item(c).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                    }
                }

                document.getElementById('input').value = '';
                this.client.textRequest(value.toString()).then(this.onResponse, this);
                this.setState((prevState) => {
                    return {
                        messages: [...prevState.messages, {
                            author: this.user,
                            text: value,
                            timestamp: new Date()
                        }]
                    }
                })
            }
            event.preventDefault();
        }
    }

    /**
     * Function that starts an event when ending video
     * @param {object} event
     */
    onPauseVideo = event => {
        this.setState({ onKeyPressUser: true });
    }

    /**
     *  Function that starts an event when end video
     * @param {object} event
     */
    onEndVideo = event => {
        if (this.state.onKeyPressUser === false) {
            switch (this.intentName) {
                // CASE HERO
                case 'NotLoggedin.AppUnico.Info.yes':
                    this.client.eventRequest("FIM_VIDEO_HERO").then(this.onResponse, this);
                    break;
                // CASE CLOUD
                case 'NotLoggedin.Cloud.Info.yes':
                    this.client.eventRequest("FIM_VIDEO_CLOUD").then(this.onResponse, this);
                    break;
                // CASE CONTROLE PARENTAL
                case 'Loggedin.AppUnico.Info.Usability.Family.yes':
                    this.client.eventRequest("FIM_VIDEO_PARENTAL").then(this.onResponse, this);
                    break;
                // CASE WI-FI
                case 'Loggedin.AppUnico.Info.Usability.Wifi.yes':
                    this.client.eventRequest("FIM_VIDEO_WIFI").then(this.onResponse, this);
                    break;
                // CASE SECURITY
                case 'Loggedin.AppUnico.Info.Usability.Security.yes':
                    this.client.eventRequest("FIM_VIDEO_SECURITY").then(this.onResponse, this);
                    break;
                // CASE OPTIMIZER 
                case 'Loggedin.AppUnico.Info.Usability.Optimizer.yes':
                    this.client.eventRequest("FIM_VIDEO_OPTIMIZER").then(this.onResponse, this);
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Function get message input text
     * @param {string} value 
     */
    handleClickButtonStep(value) {
        if (value) {
            let btn = document.getElementsByClassName('content-buttons');

            this.setState({ loading: true });

            for (var i = 0; i < btn.length; i++) {
                btn[i].remove();
            }

            this.client.textRequest(value.toString()).then(this.onResponse, this);
            this.setState((prevState) => {
                return {
                    messages: [...prevState.messages, {
                        author: this.user,
                        text: value,
                        timestamp: new Date()
                    }]
                }
            })
        }
    }

    /**
     * Function get question messages input text
     * @param {string} value 
     */
    handleClickQuestionStep(value) {
        if (value) {

            this.setState({ loading: true });

            let lst = document.getElementsByClassName('content-questions');

            for (var i = 0; i < lst.length; i++) {
                lst[i].remove();
            }

            let btn = document.getElementsByClassName('content-buttons');

            for (var c = 0; c < btn.length; c++) {
                btn[c].remove();
            }

            this.client.textRequest(value.toString()).then(this.onResponse, this);
            this.setState((prevState) => {
                return {
                    messages: [...prevState.messages, {
                        author: this.user,
                        text: value,
                        timestamp: new Date()
                    }]
                }
            })
        }
    }

    /*
     * Function get carousel output messages 
     */
    onClickProduct(product) {
        switch (product) {
            case 'BÁSICO':
            case 'BASICO':
                this.client.eventRequest("VENDA_BASICO").then(this.onResponse, this);
                break;
            case 'PREMIUM':
                this.client.eventRequest("VENDA_PREMIUM").then(this.onResponse, this);
                break;
            case 'ESSENCIAL':
                this.client.eventRequest("VENDA_ESSENCIAL").then(this.onResponse, this);
                break;
            case 'BANDA LARGA':
                this.client.eventRequest("VENDA_BANDALARGA").then(this.onResponse, this);
                break;
            case 'MÓVEL':
            case 'MOVEL':
                this.client.eventRequest("VENDA_MOVEL").then(this.onResponse, this);
                break;
            case 'BUSINESS':
                this.client.eventRequest("VENDA_BUSINESS").then(this.onResponse, this);
                break;
            case 'CENT. SEGURANÇA':
            case 'CENT. SEGURANCA':
                this.client.eventRequest("VENDA_CENT.SEGURANCA").then(this.onResponse, this);
                break;
            default:
                break;
        }
    }

    /**
     * Function get list buttons
     * @param {object} data 
     */
    getButtons(data) {
        return data.suggestedActions.map((response, key) => {
            return <ButtonStep onClick={() => this.handleClickButtonStep(response.value)} name={response.title} key={key} />
        })
    }

    /**
     * Function get list questions
     * @param {object} data
     */
    getListQuestions(data) {
        return data.suggestedActions.map((response, key) => {
            return <ListQuestionStep onClick={() => this.handleClickQuestionStep(response.value)} name={response.title} key={key} />
        })
    }

    /**
     * Function get carousel
     * @param {object} data
     */
    getCarousel(data) {
        return <CarouselStep onClickProduct={(product) => this.onClickProduct(product)} data={data} />;
    }

    /**
     * Get messages the bot
     * @param {object} data 
     */
    getMessages(data) {

        switch (data.author.id) {
            case 1:
                return <ContentUser text={data.text} />;
            case 2:
                return <ContentBot text={data.text} />;
            case 3:
                return <ContentButtons>
                    {this.getButtons(data)}
                </ContentButtons>;
            case 4:
                return <ContentVideo>
                    <Video
                        onPauseVideo={(e) => this.onPauseVideo(e)}
                        onEndVideo={(e) => this.onEndVideo(e)}
                        url={data.suggestedActions[0].value} />
                </ContentVideo>;
            case 5:
                return <ContentButtons>
                    <ButtonLink
                        url={data.suggestedActions[0].value}
                        name={data.suggestedActions[0].title} />
                </ContentButtons>;
            case 6:
                return <ContentListQuestions>
                    {this.getListQuestions(data)}
                </ContentListQuestions>;
            case 7:
                return <ContentCarousel>
                    {this.getCarousel(data)}
                </ContentCarousel>;
            case 8:
                return <Cards
                    image={data.suggestedActions[0].image}
                    subtitle={data.suggestedActions[0].subtitle}
                    redirect_uri={data.suggestedActions[0].redirect_uri} />;
            default:
                return '';
        }
    }

    /**
     * Render Component
     */
    render() {

        const {
            botName,
            botTitle,
            messages,
            loading,
            placeholder
        } = this.state;

        let loader;

        if (loading) {
            loader = <Loading />
        }

        return (
            <ChatBotContainer>
                <Header botName={botName} botTitle={botTitle} />
                <ContentChatBot>
                    {messages.map((data, key) => (
                        <React.Fragment key={key}>
                            {this.getMessages(data)}
                        </React.Fragment>
                    ))}
                    {loader}
                </ContentChatBot>
                <Footer>
                    <InputForm placeholder={placeholder} onChange={this.handleChange} onKeyPress={this.handleKeyPress} handleSubmit={this.handleSubmit} />
                </Footer>
            </ChatBotContainer>
        );
    }
}