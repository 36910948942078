import axios from 'axios'
import uuidv4 from 'uuid/v4'
import * as env from '../constants'

const URL = env.URL_DIALOG_FLOW
const TOKEN = env.TOKEN_DIALOG_FLOW
const HEADERS =  {
    'Content-Type': 'application/json',
    'Authorization': `Basic ${TOKEN}`
}

export default class RequestBot {
    constructor() {
        this.queryInput = {
            query: null,
            type: null,
            sessionId: uuidv4()
        }
    }

    textRequest(query) {
        this.queryInput.query = query;
        this.queryInput.type = 'message';
        return this.run(this.queryInput)
    }

    eventRequest(query) {
        this.queryInput.query = query;
        this.queryInput.type = 'event';
        return this.run(this.queryInput)
    }

    run(queryInput) {
        return new Promise(function(resolve, reject) {
            axios.post(URL, JSON.stringify(queryInput), { headers: HEADERS })
            .then(res => resolve(res.data[0]))
            .catch(err => {
                console.error('RequestBot Error', err);
                reject();
            })
        })
    }
}