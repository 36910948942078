import React from 'react'
import './styles.css'

export default (props) => {

    return (
        <div id="content-carousel" className="content-carousel">
            {props.children}
        </div>     
    )
}