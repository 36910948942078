import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.css'

export default class Carousel extends Component {

    slider() {
        return this.props.data.suggestedActions.map((response, key) => {
            // Carrossel de assinaturas
            if (response.button) {
                return  <div key={key} onClick={() => this.props.onClickProduct(response.value) }>
                            <div className="carousel">
                                <img width="100%" src={response.img} alt={response.value} />
                                <div className="carousel-text">
                                    <strong className="carousel-title">{response.title}</strong>
                                    <p className="carousel-subtitle">Características</p>
                                    <p style={{ whiteSpace: 'pre-line' }} className="carousel-description">{response.description}</p>
                                </div>
                                <button onClick={() => window.open(response.redirect_uri.stringValue, '_blank')} className="carousel-btn-my-account" type="button">{response.button.stringValue}</button>
                            </div>
                        </div>
            } 
            // Carrossel de produtos à venda
            else {
                return  <div key={key} onClick={() => this.props.onClickProduct(response.value) }>
                            <div className="carousel" style={{ height: '280px'}}>
                                <img width="100%" src={response.img} alt={response.value} />
                                <div className="carousel-text">
                                    <strong style={{ fontSize: '14px' }}>{response.title}</strong>
                                    <p style={{ fontSize: '12px', color: '#2dbcb5' }}>Características</p>
                                    <p style={{ fontSize: '12px', whiteSpace: 'pre-line' }}>{response.description}</p>
                                </div>
                            </div>
                        </div>
            }
        })
    }

    render() {

        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 3000,
            className: "center",
            centerMode: true,    
        };

        return (
            <Slider {...settings}>
                {this.slider()}
            </Slider>
        );
    }
}