import React from 'react'
import './styles.css'

export default (props) => {
    return (
        <a href={props.url} 
            target="_blank"
            rel="noopener noreferrer"
            className="button-link">{props.name}</a>
    )
}