import React from 'react';
import './styles.css'

export default (props) => {
    return (
        <div className="card">
            <img width="270" height="270" src={ props.image } alt="Card" />
            <div className="card-text">
                <strong style={{ fontSize: '16px' }}>MEU PLANO</strong>
                <p style={{ fontSize: '14px', color: '#2dbcb5' }}>Características:</p>
                <p style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>{ props.subtitle }</p>
            </div>
            <button onClick={() => window.open(props.redirect_uri, '_blank')} className="card-btn-my-account" type="button">+ MINHA CONTA</button>
        </div>
    );
}