import React from 'react'
import './styles.css'

export default (props) => {
    return (
        <React.Fragment>
            <form onSubmit={props.handleSubmit}>
                <input 
                    id="input"
                    type="text"
                    value={props.message}
                    onChange={props.onChange}
                    onKeyPress={props.onKeyPress}
                    className="input-form" 
                    placeholder={props.placeholder} 
                    autoComplete="off"
                    autoFocus 
                />
                <button className="input-icon" onClick={props.handleSubmit}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 500 500">
                        <g>
                            <g>
                                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                            </g>
                        </g>
                    </svg>
                </button>
            </form>    
        </React.Fragment>
    )
}