import React from 'react'
import './styles.css'
import logoIcon from './images/hero-logo.png'

export default (props) => {
    return (
        <div className="header">
            <img src={logoIcon} alt="Logo" className="logo" />
            <div className="title">
                <h2 className="text-bot-name">{props.botName}</h2>
                <h5 className="text-bot-title">{props.botTitle}</h5>
            </div>
            <div></div>
            {props.children}
        </div>
    )
}