import React from 'react'
import YouTube from 'react-youtube'

import './styles.css'

export default class Video extends React.Component {

    /**
     * Constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);
        let url = this.props.url.split('=');

        this.state = {
            displayVideo: 'none',
            displayButtonVideo: 'block',
            autoplay: false,
            videoId: url[1]
        }
    }

    /**
     * On Click Button
     */
    onClick = () => {
        this.setState({
            displayVideo: 'block',
            displayButtonVideo: 'none',
            autoplay: true
        });
    }

    /**
     * Render Component
     */
    render() {

        const {
            displayButtonVideo,
            displayVideo,
            autoplay
        } = this.state;

        let opts;

        if (autoplay) {
            opts = {
                height: '200px',
                width: '100%',
                playerVars: {
                    autoplay: 1
                }
            };
        }

        return (
            <>
                <button type="button" style={{ display: displayButtonVideo }} className="button-select-video" onClick={this.onClick}>Veja o vídeo</button>
                <div style={{ display: displayVideo }}>
                    <YouTube
                        videoId={this.state.videoId}
                        opts={opts}
                        className="video"
                        onPause={(e) => this.props.onPauseVideo(e)}
                        onEnd={(e) => this.props.onEndVideo(e)}
                    />
                </div>
            </>
        );
    }
}