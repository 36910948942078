import React from 'react'
import './styles.css'

export default (props) => {
    return (
        <button 
            onClick={props.onClick}
            type="button" 
            className="buttons-step">{props.name}</button>
    )
}