import React from 'react'
import './styles.css'

import BOT_ICON from './images/hero-hector-avatar-02.png'

export default props => {
    return (
        <div className="content-bot">
            <img src={BOT_ICON} className="content-image-bot" alt="avatar" />
            <div className="content-text-bot">
                {props.text}
            </div>
        </div>
    )
}