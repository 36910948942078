import React from 'react'
import IMAGE from './images/loading.gif'
import BOT_ICON from './images/hero-hector-avatar-02.png'
import './styles.css'

export default () => {
    return (
        <div className="content-bot">
            <img src={BOT_ICON} className="content-image-bot" alt="avatar" />
            <img src={IMAGE} alt="Loading" width="60px"/>
        </div>
    )
}